

















































import { UserHelpers } from "@/includes/helpers/UserHelpers"
import UserInfoAvatar from "@/components/UserInfoAvatar.vue"
import UserReferralsTable from '@/components/UserReferralsTable.vue'
import { errorNotification } from "@/includes/NotificationService";
import UserProfileApi from "@/includes/Api/UserProfile.api";
import { UserProfile } from '@/includes/types/UserProfile/types'
import UserProfileStatistics from '@/components/UserProfile/UserProfileStatistics.vue'
import UserProfileInfo from '@/components/UserProfile/UserProfileInfo.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'

import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import VueApexCharts from "vue-apexcharts";
import axios from 'axios'
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    UserProfileInfo,
    UserProfileStatistics,
    UserInfoAvatar,
    VueApexCharts,
    UserReferralsTable,
    PageTitle,
    EmptyData,
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      UserHelpers
    }
  },
})
export default class UserInfo extends Vue {

  referralsDataGenTime = null

  referralData = null

  userData: UserProfile | null = null

  @Watch('$route.params.userId')
  onUserIdParamChange() {
    this.userData = this.referralData = null

    this.getProfileInfo()
    this.getReferrals()
  }

  getProfileInfo() {
    UserProfileApi.getUsersProfileInfo(
      this.$store.getters.chatId,
      [ parseInt(this.$route.params.userId) ],
    )
      .then(({ data }) => {
        this.userData = data.users[0]
      })
      .catch(errorNotification)
  }

  getReferrals() {
    if (this.$store.getters.isChatAtLeastAdvanced) {
      axios.get(`https://api.chatkeeper.app/cabinet/static/privatechatreferrals/${ this.$store.getters.chatId }_34.json`)
        .then(({ data }) => {
          this.referralsDataGenTime = data.gentime
          this.referralData = data.users.filter((user: any) => user.user_id === parseInt(this.$route.params.userId))[0]
        })
        .catch(errorNotification)
    }
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }

  created() {
    this.getProfileInfo()
    this.getReferrals()
  }
}
